import type { MaybeRef, WritableComputedRef } from 'vue'
import type { OptionRentReviewTable } from '../types'
import type {
  IOptionsConfiguration,
  IOptionsForm,
} from '@manager/components/Group/Custom/OptionExerciseDetailsTable/types'
import { convertToRentReviewTable } from '@manager/components/Group/Custom/RentReviewTable/utils'
import {
  FieldUsage,
  useOptionsGenerator,
  useTableData,
  REPEATER_ITEM_STATUS,
} from '@manager'

export const useRentReviewData = (
  initialValue: MaybeRef<OptionRentReviewTable[]>,
  formValue: WritableComputedRef<IOptionsForm>,
) => {
  const dayjs = useDayjs()
  const expiryDate = computed(
    () => formValue.value.supportFieldsGroup?.data?.[0]?.expiryDate,
  )

  const { onSubmit: _submit } = useManagerNodeSubmit(FieldUsage.INTERACTIVE)

  const { data, filteredData, add, addOrUpdate, updateById, remove, getById } =
    useTableData<OptionRentReviewTable>(initialValue, {
      initialItem: computed(() => ({
        method: convertDefaultMethod(
          formValue.value.optionInitialRentIncreaseType,
        ),
        fixedAmount: formValue.value.fixedAmount,
        fixedPercent: formValue.value.fixedPercentage,
        cpiPercent: formValue.value.cpiPercent,
        cPIOption: formValue.value.cPIOption,
        cpiCollar: formValue.value.cpiCollar,
        cpiCap: formValue.value.cpiCap,
        greaterOf: formValue.value.greaterOf,
        lesserOf: formValue.value.lesserOf,
        marketReviewDate: formValue.value.marketReviewDate,
        marketOption: formValue.value.marketOption,
      })),
      initialItemCondition: ({ item }) => item.__review === 1,
      watchInitialItem: true,
      onAdd: (item) => {
        Object.defineProperty(item, '__review', {
          writable: true,
          enumerable: false,
        })
        Object.defineProperty(item, '__endDate', {
          writable: true,
          enumerable: false,
        })
        _submit()
      },
      onUpdate: () => {
        _submit()
      },
    })

  const isEmpty = computed(() => filteredData.value.length === 0)

  const generate = useOptionsGenerator({
    onAddReview: (index, { option, review, months, days }) => {
      const previousItem = data.value[index - 1]
      const previousEndDate = previousItem?.__endDate ?? expiryDate.value
      const item = data.value[index]
      const status = [REPEATER_ITEM_STATUS.CREATED, REPEATER_ITEM_STATUS.EDITED]
      if (item && status.includes(item.__status)) return

      addOrUpdate(index, {
        option,
        date: dayjs.utc(previousEndDate).add(1, 'day').toISOString(),
        __review: review,
        __endDate: (months !== undefined && days !== undefined
          ? dayjs.utc(previousEndDate).add(months, 'months').add(days, 'days')
          : dayjs.utc(previousEndDate).add(1, 'year')
        ).toISOString(),
        __status: REPEATER_ITEM_STATUS.GENERATED,
      })
    },
    onRemoveReview: (index: number) => {
      const item = data.value[index]
      if (item) remove(item, index)
    },
    maxIterations: 200,
  })

  debouncedWatch(
    () => formValue.value.optionsConfiguration?.data ?? [],
    (newConfigurations) => {
      if (!expiryDate.value) return

      generate(
        newConfigurations.filter(
          (config) => config.__status !== REPEATER_ITEM_STATUS.DELETED,
        ),
      )
    },
    // debounce must be lower than evaluate debounce (300)
    { immediate: true, deep: true, debounce: 150 },
  )

  return {
    items: data,
    filteredItems: computed(() =>
      filteredData.value.map(convertToRentReviewTable),
    ),
    add,
    updateById,
    getById,
    isEmpty,
  }
}

function convertDefaultMethod(method: string | undefined | null) {
  switch (method) {
    case 'FixedAmount':
      return 'FixAmount'
    case 'FixedPercentage':
      return 'FixPercentage'
    default:
      return method
  }
}
